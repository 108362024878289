import { createSelector } from '@ngrx/store'
import { HubtypeProject } from 'models/hubtype-project'
import { HubtypeQueue } from 'models/hubtype-queue'
import { assertDeserialize } from 'utils/json-utils'
import { HubtypeActiveUsers } from '../models/hubtype-active-users'
import { HubtypeFolder } from '../models/hubtype-folder'
import { HubtypeMessage } from '../models/hubtype-message'
import { HubtypeProviderAccount } from '../models/hubtype-provider-account'
import {
  HubtypeUser,
  ROLE_ADMIN,
  ROLE_AGENT,
  ROLE_MANAGER,
} from '../models/hubtype-user'
import { AiState } from './ai.state'
import * as authReducer from './auth.state'
import * as botReducer from './bot.reducer'
import { DeskState } from './desk.state'
import { InsightsState } from './insights.state'
import { OrganizationState } from './organization.state'
import { RunnableTask } from './runnable-task'
import { TimestampedData } from './shared.state'
import { StatsState } from './stats.state'
import { TemplatesState } from './templates.state'
import { UserSettingsState } from './user-settings.state'

export interface State {
  ai: AiState
  auth: authReducer.State
  bot: botReducer.State
  desk: DeskState
  organization: OrganizationState
  stats: StatsState
  templates: TemplatesState
  tasks: RunnableTask[]
  users: HubtypeUser[]
  userSettings: UserSettingsState
  insights: InsightsState
}
/**Cases list */
export const getCasesFilter = (state: State) => state.desk.inbox.casesFilter
export const getCasesSort = (state: State) => state.desk.inbox.casesSort
// export const getAttendingList = (state: State) => state.desk.inbox.attending
// export const getIdleList = (state: State) => state.desk.inbox.idle
/** */
export const getAuthState = (state: State) => state.auth
export const getAccessTokenState = (state: State) => state?.auth?.oauth
export const getMeState = (state: State) =>
  assertDeserialize(state?.auth?.me, HubtypeUser)

export const getMeId = (state: State) => state?.auth?.me?.id
export const getMeOrganization = (state: State) =>
  state?.auth?.me?.organization_id
export const isLoggedIn = (state: State) => !!state?.auth?.is_logged_in
export const getPusherConnectedStatus = (state: State) =>
  state?.auth?.pusherConnectionStablished
export const getError = (state: State) => (state.auth ? state.auth.error : null)

export const getBotState = (state: State) => state.bot
export const getBots = (state: State) =>
  state.bot.bots
    .filter(b => b.is_botonic)
    .sort((b1, b2) => (b1.name.toLowerCase() < b2.name.toLowerCase() ? -1 : +1))
export const getBotById = (id: string) => (state: State) =>
  state.bot.bots.find(b => b.id === id)
export const getBot = (state: State) => state.bot.selectedBot
export const getBotId = (state: State) =>
  state.bot.selectedBot ? state.bot.selectedBot.id : null
export const getBotUpdates = (state: State) => state.bot.selectedBotUpdates

export const getDashboardCases = (state: State) => state.desk.dashboard.cases
export const getProviderAccount = id => (state: State) =>
  []
    .concat(
      ...state.organization.queues.map(q => q.provider_accounts),
      ...state.bot.bots.map(b => b.provider_accounts)
    )
    .find(pa => pa.id === id)
export const getAllProviderAccounts = (state: State) => {
  const pas = [].concat(
    ...state.organization.queues.map(q => q.provider_accounts),
    ...state.bot.bots.map(b => b.provider_accounts)
  )
  //@TODO this is a new object on every evaluation causing an infinite loop on redux select
  const distinctProviderAccount: { [id: number]: HubtypeProviderAccount } = {}
  for (const pa of pas) {
    if (pa) {
      distinctProviderAccount[pa.id] = pa
    }
  }
  return Object.values(distinctProviderAccount)
}
export const getAllDistincProviderAccounts = (state: State) =>
  state.bot.bots
    .map(b => b.provider_accounts.map(prov => prov.provider))
    //Flatmap (plain multiples arrays)
    .reduce((acc, val) => acc.concat(val), [])
    //Remove duplicated
    .filter((item, pos, arr) => arr.indexOf(item) === pos)

export const getWhatsappProviderAccounts =
  (includePlayground: boolean = false) =>
  (state: State) =>
    state.bot.bots
      .map(b => b.provider_accounts)
      .reduce((acc, val) => acc.concat(val), [])
      .filter(pa => pa.isWhatsapp && pa.is_playground === includePlayground)

export const getArchiveCases = (state: State) =>
  state.desk.archive.cases.map(ac => ac.case)
export const getArchiveFilters = (state: State) => state.desk.archive.filters

export const getArchiveNext = (state: State) => state.desk.archive.next
export const getArchivePrev = (state: State) => state.desk.archive.prev

export const getArchiveSelectedCase = (state: State) =>
  state.desk.archive.selectedCase
export const getArchiveCaseMessages = (state: State) =>
  state.desk.archive.selectedCaseMessages

export const getSelectedCase = (state: State) => state.desk.inbox.selectedCase
export const getCase = id => (state: State) =>
  [
    ...state.desk.inbox.attending,
    ...state.desk.inbox.idle,
    ...state.desk.inbox.waiting,
  ].find(c => c.id === id)

export const getArchiveCase = id => (state: State) =>
  [
    ...state.desk.archive.cases.map(ac => ac.case),
    state.desk.archive.selectedCase?.case,
  ].find(c => c?.id === id)

export const getWaitingCases = (state: State) => state.desk.inbox.waiting

export const getQueues = (state: State) => state.organization.queues
export const getQueueAgents = queueId => (state: State) =>
  state.organization.users.filter(u => u.queues.some(q => q.id === queueId))
export const getQueueById = queueId => (state: State) =>
  state.organization.queues.find(q => q.id === queueId)

export const getSelectedCaseMessages = (state: State): HubtypeMessage[] =>
  state.desk.inbox.selectedCaseMessages

export const selectProjectQueue = (projectId: string, queueId: string) =>
  createSelector(getProjects, getQueues, (allProjects, allQueues) => {
    const project = allProjects.find(p => p.id === projectId)
    const queue = allQueues.find(q => q.id === queueId)
    return {
      project,
      queue,
    }
  })

export const getOrganization = (state: State) =>
  state?.organization?.organization
export const getTemplateFolders = (state: State): HubtypeFolder[] =>
  state.organization.template_folders
export const getTemplates = (state: State) => state.organization.templates
export const getProjects = (state: State) => state.organization.projects

export const getProject = id => (state: State) => getProjectById(state, id)

export const getSelectedProject = (state: State) =>
  state.organization.settings.selectedProject

export const selectAllQueues = (state: State): HubtypeQueue[] =>
  // TODO: When refactor the state, this should be on the root
  state?.organization?.queues || []

export const getQueueByIdFromOrg = queueId => (state: State) =>
  state.organization.queues.find(q => q.id === queueId)

export const getQueuesOfProject = projectId => (state: State) =>
  state.organization.queues.filter(q => q.project_id === projectId)

/**
 * @deprecated Use selectTransferableQueues instead
 */
export const getTransferableQueues = projectId => (state: State) => {
  if (state.organization.organization.allow_transfer_all_projects) {
    return state.organization.queues
  }
  return state.organization.queues.filter(q => q.project_id === projectId)
}

export const getSelectedQueue = (state: State) =>
  state.organization.queues.find(q => {
    try {
      if (q.id === state.organization.settings.selectedQueue.id) {
        return true
      }
    } catch (e) {
      return false
    }
  }) // settings.selectedQueue;

export const getUsers = (state: State) => state.organization.users

export const getUsersByProject = projectId => (state: State) =>
  state.organization.users.filter(u => u.projects.find(p => p.id === projectId))

export const getUsersByQueue = queueId => (state: State) =>
  state.organization.users.filter(u => u.queues.find(q => q.id === queueId))

export const getUser = id => (state: State) =>
  state.organization.users.find(u => u.id === id)

export const selectUsers = (state: State) => state?.users || []

export const meQueues = (state: State) => state?.auth.me?.queues

export const getMeProjectQueueVM = createSelector(
  getProjects, //Projects visible by me
  getQueues, //All queues of my visible projects
  meQueues, //Queues visible by me
  selectUsers, //Users in organization
  getMeState, //Me
  (
    projects,
    queues,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    meQueues: { id: string; name: string }[],
    users,
    me: HubtypeUser
  ) => {
    let projectsList: HubtypeProject[] = projects.map(project => {
      const filterByAgent = projectQueue =>
        me.is_agent
          ? meQueues.find(meQueue => meQueue.id === projectQueue.id)
          : projectQueue

      project.queues = queues
        .filter(
          //Fill meQueues visibility by project
          projectQueue =>
            filterByAgent(projectQueue) && //Exist in my queue list if you are an agent
            projectQueue.project_id === project.id //ProjectId match
        )
        .map(queue => {
          queue.setAgentsConnectd(users)
          return queue
        })
      return project
    })

    //Clean empty projects
    if (me?.is_agent) {
      projectsList = projectsList.filter(project => project.queues.length > 0)
    }
    return { projectsList, me }
  }
)

export const getMeProjects = createSelector(
  getProjects,
  (
    projects //Projects visible by me
  ) => projects
)

export const selectUsersByRole = (role: string) =>
  createSelector(selectUsers, users => users.filter(u => u.role === role))

export const selectUsersInRoles = (roles: string[]) =>
  createSelector(selectUsers, users =>
    users.filter(u => roles.includes(u.role))
  )

export const selectAdmins = selectUsersByRole(ROLE_ADMIN)
export const selectAgents = selectUsersByRole(ROLE_AGENT)
export const selectManagers = selectUsersByRole(ROLE_MANAGER)

export const selectTransferableAgents = createSelector(
  getMeState,
  selectUsersInRoles([ROLE_AGENT, ROLE_MANAGER]),
  (me, users) => {
    if (me?.role === ROLE_AGENT) {
      return []
    }
    return users
  }
)

export const selectChannels = () =>
  HubtypeProviderAccount.ALL_PROVIDERS.map(provider => ({
    id: provider,
    name: HubtypeProviderAccount.names[provider],
  }))

export const selectFiltersData = createSelector(
  getProjects,
  getQueues,
  getUsers,
  selectChannels,
  (projects, queues, users, channels) => ({ projects, queues, users, channels })
)

/**
 * @deprecated Use {@link selectUsersByRole} instead
 * @param state The global state
 * @param role The role to filter
 * @returns The list of users with a specific role
 */
export const usersByRole = (state, role) =>
  getUsers(state).filter(user => user.role === role)

/** @deprecated Use {@link selectAdmins} instead */
export const admins = (state: State) => usersByRole(state, ROLE_ADMIN)
/** @deprecated Use {@link selectManagers} instead */
export const managers = (state: State) => usersByRole(state, ROLE_MANAGER)
/** @deprecated Use {@link selectAgents} instead */
export const agents = (state: State) => usersByRole(state, ROLE_AGENT)

export const getAgentsInQueue =
  id =>
  // filter agents available, then filter agents with queue same id as we wanted
  (state: State) =>
    agents(state).filter(a => a.queues.find(q => q.id === id))

export const getManagersInProject =
  id =>
  // filter agents available, then filter agents with queue same id as we wanted
  (state: State) =>
    managers(state).filter(m =>
      m.projects ? m.projects.find(p => p.id === id) : null
    )

export const getBusyAgentsInQueue =
  queueId =>
  // filter agents busy, then filter agents with queue same id as we wanted
  (state: State) =>
    [...getAgentsInQueue(queueId)(state)].filter(a => a.isBusy)

export const getAvailableAgentsInQueue =
  queueId =>
  // filter agents available, then filter agents with queue same id as we wanted
  (state: State) =>
    [...getAgentsInQueue(queueId)(state)].filter(a => a.isAvailable)

export const getUsersInQueue =
  (queueId, projectId) =>
  // filter agents available, then filter agents with queue same id as we wanted
  (state: State) => [
    ...getAgentsInQueue(queueId)(state),
    ...getManagersInProject(projectId)(state),
    ...admins(state),
  ]

export const getProjectById = (state: State, id: string) =>
  state.organization.projects.find(p => p.id === id)

////// Reporting
export const getReportingFilters = (state: State) =>
  state.desk.reporting.filters

export const getMaus = (
  state: State
): { [year: number]: TimestampedData<HubtypeActiveUsers[]> } =>
  state?.stats?.maus || []

export const getMausByYear =
  (year: number) =>
  (state: State): TimestampedData<HubtypeActiveUsers[]> =>
    getMaus(state)[year]

export const closeCaseChatWindow = (state: State) =>
  state.desk.inbox.closeCaseChatWindow

export const isNavbarCollapse = (state: State) =>
  state.desk.inbox.navbarCollapse

export const organizationAndMe = createSelector(
  getOrganization,
  getMeState,
  (organization, me) => ({
    organization,
    me,
  })
)
