<div class="dropdown" role="listbox" [attr.aria-label]="label" #dropdownBtn>
  <button
    ht-select-button
    (click)="toggleDropdown()"
    [attr.data-testid]="testId"
    [isDisabled]="disabled"
    [appearance]="buttonAppearance"
    [size]="buttonSize"
    [fullWidth]="fullWidth"
    [expanded]="isDropdownOpen"
    type="button"
  >
    <div class="selected">
      <div *ngIf="selectedValue">{{ selectedValue }}</div>
      <div *ngIf="placeholder && !selectedValue" class="placeholder">
        {{ placeholder }}
      </div>
    </div>
    <i
      class="fa-solid"
      [ngClass]="isDropdownOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
      *ngIf="!hideArrow"
    ></i>
  </button>
</div>

<div
  [style.min-width.px]="dropdownMenuWidth"
  [ngClass]="{ opened: isDropdownOpen }"
  class="dropdown-menu"
>
  <ht-search-bar
    *ngIf="searchBarPlaceholder"
    class="search-bar"
    type="secondary"
    size="small"
    [placeholder]="searchBarPlaceholder"
    (search)="filterItems($event)"
    [(ngModel)]="searchValue"
  ></ht-search-bar>
  <div role="list" class="content">
    <ng-container *ngIf="!isMultiSection; else multiSectionData">
      <ng-container
        *ngTemplateOutlet="
          dropdownItemTemplate;
          context: { data: filteredData }
        "
      ></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #multiSectionData>
  <div class="section" *ngFor="let section of filteredData">
    <div *ngIf="section.title" class="section-title">
      {{ section.title }}
    </div>
    <ng-container
      *ngTemplateOutlet="dropdownItemTemplate; context: { data: section.items }"
    ></ng-container>
  </div>
</ng-template>

<ng-template #dropdownItemTemplate let-data="data">
  <div
    class="dropdown-menu-item"
    (click)="onSelect(item.id)"
    *ngFor="let item of data"
    [ngClass]="item.id === selectedId ? 'active' : ''"
    role="option"
    [attr.aria-label]="item.value"
    >{{ item.value }}</div
  >
</ng-template>
