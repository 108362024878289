import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core'
import {
  BasePlacement,
  createPopper,
  Instance,
  Placement,
} from '@popperjs/core'
import { fromEvent, merge, Subject } from 'rxjs'
import { filter, takeUntil } from 'rxjs/operators'

export type TooltipEventTrigger = 'hover' | 'click'

@Directive({
  selector: '[tooltip], [tooltipOnClick], [tooltipElement]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  // Its positioning (check docs for available options)
  @Input() tooltipPlacement?: Placement
  @Input() tooltipDismissTimeout?: number
  @Input() tooltipEventTrigger?: TooltipEventTrigger
  @Input() tooltipOffset?: [number, number]

  // The popper instance
  private popper: Instance
  private readonly defaultConfig = {
    placement: 'top' as BasePlacement,
    removeOnDestroy: true,
    eventsEnabled: false,
    strategy: 'fixed' as const,
  }

  private bodyElement = document.querySelector('body')

  private _tooltipEventTrigger: 'hover' | 'click'

  private readonly TOOLTIP_DISMISS_TIMEOUT = 1000

  private readonly destroy$ = new Subject<void>()

  constructor(
    private readonly el: ElementRef,
    private readonly renderer: Renderer2
  ) {}

  // The text to display
  @Input()
  tooltip: string

  // Text to display on click only
  @Input()
  tooltipOnClick: string

  // The element to display as a tooltip
  @Input()
  tooltipElement: HTMLElement

  @Output()
  tooltipOpening = new EventEmitter<boolean>()

  ngOnInit(): void {
    if (!this.tooltip && !this.tooltipOnClick && !this.tooltipElement) {
      return
    }

    this.initializeTooltip()

    if (!this.tooltipOnClick) {
      fromEvent(this.bodyElement, 'click')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.closeTooltip()
        })
    }

    merge(
      fromEvent(this.el.nativeElement, 'mouseenter'),
      fromEvent(this.el.nativeElement, 'mouseleave'),
      fromEvent(this.el.nativeElement, 'click')
    )
      .pipe(
        filter(() => this.popper !== null),
        takeUntil(this.destroy$)
      )
      .subscribe((event: Event) => {
        const eventType = event.type
        if (
          eventType === 'mouseenter' &&
          this.tooltipEventTrigger === 'hover'
        ) {
          this.updateTooltipText(this.tooltip)
          this.openTooltip()
        } else if (
          eventType === 'mouseleave' &&
          this.tooltipEventTrigger === 'hover'
        ) {
          this.closeTooltip()
        } else if (
          eventType === 'click' &&
          (this.tooltipEventTrigger === 'click' || this.tooltipOnClick)
        ) {
          if (this.tooltipOnClick || this.tooltip) {
            this.updateTooltipText(this.tooltipOnClick || this.tooltip)
          }
          this.openTooltipOnClick()
        }
      })
  }

  ngOnDestroy(): void {
    this.popper?.destroy()

    this.destroy$.next()
    this.destroy$.complete()
  }

  private initializeTooltip(): void {
    this.tooltipEventTrigger =
      this.tooltipEventTrigger || (this.tooltip ? 'hover' : 'click')
    this._tooltipEventTrigger = this.tooltipEventTrigger

    if (!this.tooltipElement) {
      this.tooltipElement = document.createElement('div')
      this.tooltipElement.classList.add('ht-tooltip')
      this.updateTooltipText(this.tooltip || this.tooltipOnClick)
      this.el.nativeElement.appendChild(this.tooltipElement)
    }

    // An element to position the text relative to the element to hover
    this.popper = createPopper(this.el.nativeElement, this.tooltipElement, {
      ...this.defaultConfig,
      placement: this.tooltipPlacement || this.defaultConfig.placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: this.tooltipOffset || [0, 5],
          },
        },
      ],
    })

    this.renderer.setStyle(this.tooltipElement, 'display', 'none')
    this.renderer.setStyle(this.tooltipElement, 'z-index', '101')
    this.renderer.setStyle(this.tooltipElement, 'text-wrap', 'wrap')
  }

  private updateTooltipText(value: string | HTMLElement): void {
    if (typeof value !== 'string') {
      return
    }
    if (value) {
      this.tooltipElement.innerHTML = value
      this.tooltipElement.style.visibility = 'visible'
    } else {
      this.tooltipElement.style.visibility = 'hidden'
    }
  }

  private openTooltip(): void {
    this.renderer.removeStyle(this.tooltipElement, 'display')
    this.popper.update()
    this.tooltipOpening.emit(true)
  }

  private closeTooltip(): void {
    this.renderer.setStyle(this.tooltipElement, 'display', 'none')
    this.tooltipOpening.emit(false)
  }

  private toggleTooltip(): void {
    this.tooltipElement.style.display === 'none'
      ? this.openTooltip()
      : this.closeTooltip()
  }

  private openTooltipOnClick(): void {
    if (this.tooltipOnClick) {
      this.openTooltip()
    } else {
      this.toggleTooltip()
    }

    this.tooltipEventTrigger = 'click'

    if (this.tooltipOnClick) {
      setTimeout(() => {
        this.closeTooltip()
        this.tooltipEventTrigger = this._tooltipEventTrigger
      }, this.tooltipDismissTimeout || this.TOOLTIP_DISMISS_TIMEOUT)
    }
  }
}
