import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {} from 'rxjs'
import { map, switchMap } from 'rxjs/operators'
import {
  TransferCaseRequestAction,
  TRANSFER_CASE_REQUEST,
  TRANSFER_CASE_RESPONSE,
} from '../../actions/desk'
import { FeedbackService } from '../../services/feedback.service'
import { CaseService } from '../../services/hubtype-api/case.service'

@Injectable()
export class CaseEffects {
  transfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(TRANSFER_CASE_REQUEST),
      switchMap((action: TransferCaseRequestAction) =>
        this.caseService
          .transferCase(action.payload.case.id, action.payload)
          .pipe(map(() => ({ type: TRANSFER_CASE_RESPONSE, payload: null })))
      )
    )
  )

  constructor(
    private actions$: Actions,
    private feedbackService: FeedbackService,
    @Inject('caseService') private caseService: CaseService
  ) {}
}
