import { Action } from '@ngrx/store'
import { HubtypeCaseFilter } from 'models/hubtype-case-filter'
import { HubtypeCaseSort } from 'models/hubtype-case-sort'
import { HubtypeRealtimeCase } from 'models/hubtype-slas'
import { TransferCaseData } from 'shared/entities/transfer-data'
import { ContactReasonWithCategory, HubtypeCase } from '../models/hubtype-case'
import { HubtypeMessage } from '../models/hubtype-message'
import { HubtypeProject } from '../models/hubtype-project'
import { HubtypeQueue } from '../models/hubtype-queue'
import { HubtypeUser } from '../models/hubtype-user'
import { DeskFilters, NavbarProjectQueueFilter } from '../reducers/desk.state'
import { HubtypeCasePaginator } from '../services/hubtype-api/case-search.service.dtos'

/** Inbox actions **/
export const SELECT_INBOX_CASE = '[Desk] Select inbox case'
export const SELECT_INBOX_PROJECT = '[Desk] Select inbox project'
export const SELECT_INBOX_QUEUE = '[Desk] Select inbox queue'
export const ASSIGN_CASE_ME = '[Desk] Assign Case to me'
export const UNASSIGN_CASE = '[Desk] Unassign Case'
export const CASE_IDLE = '[Desk] Case IDLE'
export const CASE_IDLE_OUT = '[Desk] Case IDLE Out'
export const NEW_CASE = '[Desk] New Case'
export const DISCARD_CASE = '[Desk] Discard Case'
export const RESOLVE_CASE = '[Desk] Resolve Case'
export const TRANSFER_CASE_REQUEST = '[Desk] Transfer Case Request'
export const TRANSFER_CASE_RESPONSE = '[Desk] Transfer Case Response'
export const UPDATE_CASE_DESCRIPTION = '[DESK] Update Case description'
export const UPDATE_INBOX_CASE_CONTACT_REASONS =
  '[DESK] Update Ibox Case contact reasons'
export const UPDATE_ARCHIVE_CASE_CONTACT_REASONS =
  '[DESK] Update Archive Case contact reasons'
export const UPDATE_CASE_LIST =
  '[DESK] Create/Update/Delete the whole case in the correct list'
export const DELETE_CASES_BY_QUEUE = '[DESK] Delete waiting cases by queue'

export const NEW_ACK_MESSAGE_RECEIVED = '[Desk] New ACK Message Received'
export const NEW_MESSAGE_RECEIVED = '[Desk] New Message Received'
export const MESSAGE_DELETED_RECEIVED = '[Desk] Message Deleted Received'
export const LOAD_OLD_CHAT_MESSAGES = '[Desk] Update Case Messages'
export const ADD_DRAFT_MESSAGE = '[Desk] Add Draft Message'
export const UPDATE_MESSAGE = '[Desk] Update Message'
export const BAN_USER = '[Desk] Ban User'
export const UPDATE_CASE_ENDUSER_TYPING = '[Desk] Update Case Enduser Typing'
export const UPDATE_CASE_CHAT_ONLINE = '[Desk] Update Case Chat Online'
export const CLOSE_CASE_BY_ENDUSER = '[Desk] Close case by enduser'
export const MARK_TO_NOT_TRANSLATE_CASE = '[Desk] Mark case to not translate'
export const MARK_TO_TRANSLATE_CASE = '[Desk] Mark case to translate'

export const TOGGLE_NAVBAR_COLLAPSE = '[Desk] Toggle navbar collapse'

/** Realtime Dashboard actions **/
export const SET_DASHBOARD_CASES = '[Desk/dashboard] Set Cases'
export const ADD_DASHBOARD_CASE = '[Desk/dashboard] Add Case'

/** Archive actions **/
export const SET_ARCHIVE_CASES = '[Desk/archive] Set Cases'
export const SET_ARCHIVE_FILTERS = '[Desk/archive] Set Filters'
export const SELECT_ARCHIVE_CASE = '[Desk/archive] Select Archive Case'
export const UPDATE_ARCHIVE_MESSAGES = '[Desk/archive] Update Archive Messages'

/** Reporting actions **/
export const SET_REPORTING_FILTERS = '[Desk/reporting] Set Filters'

/** Cases list */
export const CASES_UPDATE_WAITING_LIST = '[Desk] Updating waiting list'
export const CASES_UPDATE_IDLE_LIST = '[Desk] Updating idle list'
export const CASES_UPDATE_ATTENDING_LIST = '[Desk] Updating attending list'
export const CASES_UPDATE_WAITING_LIST_COUNT =
  '[Desk] Update loading waiting cases count'

/**Cases filter */
export const CASES_FILTER_UPDATE = '[Desk] Updating cases filter'
export const CASES_SORT_UPDATE = '[Desk] Updating cases sort'

export const CLOSE_CASE_CHAT_WINDOW = '[Desk] Close case chat window'

export const SET_SELECTED_MESSAGES = '[Desk] Set selected messages'
export const SET_ARCHIVE_MESSAGES = '[Desk] Set archive messages'

/**Navbar filter */
export const SET_NAVBAR_FILTERS = '[Desk] Set navbar filters'
export const DELETE_NAVBAR_FILTER = '[Desk] Delete navbar filter'

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class SelectInboxCaseAction implements Action {
  readonly type = SELECT_INBOX_CASE

  constructor(public payload: HubtypeCase | null) {}
}
export class SelectInboxProjectAction implements Action {
  readonly type = SELECT_INBOX_PROJECT

  constructor(public payload: HubtypeProject | null) {}
}
export class SelectInboxQueueAction implements Action {
  readonly type = SELECT_INBOX_QUEUE

  constructor(public payload: HubtypeQueue | null) {}
}

export class AssignCaseMeAction implements Action {
  // Assign case to current user
  readonly type = ASSIGN_CASE_ME

  // payload = case
  constructor(public payload: HubtypeCase) {}
}

export class UnassignCaseAction implements Action {
  // Unassign case from current user or another
  readonly type = UNASSIGN_CASE

  // payload = case
  constructor(public payload: HubtypeCase) {}
}

export class CaseIdleAction implements Action {
  // Unassign case from current user or another
  readonly type = CASE_IDLE

  // payload = case
  constructor(public payload: HubtypeCase) {}
}

export class CaseIdleOutAction implements Action {
  // Unassign case from current user or another
  readonly type = CASE_IDLE_OUT

  // payload = case
  constructor(public payload: HubtypeCase) {}
}

export class NewCaseAction implements Action {
  // New case arrives
  readonly type = NEW_CASE

  // payload = case
  constructor(public payload: HubtypeCase) {}
}

export class DiscardCaseAction implements Action {
  // Assign case to another user
  readonly type = DISCARD_CASE

  // payload = case
  constructor(public payload: HubtypeCase) {}
}

export class ResolveCaseAction implements Action {
  readonly type = RESOLVE_CASE

  constructor(public payload: HubtypeCase) {}
}

export class TransferCaseRequestAction implements Action {
  readonly type = TRANSFER_CASE_REQUEST

  constructor(public payload: TransferCaseData) {}
}

export class MessageDeletedReceivedAction implements Action {
  readonly type = MESSAGE_DELETED_RECEIVED

  constructor(public payload: HubtypeMessage) {}
}

export class NewACKMessageReceivedAction implements Action {
  readonly type = NEW_ACK_MESSAGE_RECEIVED

  constructor(public payload: HubtypeMessage) {}
}

export class LoadOldChatMessagesAction implements Action {
  readonly type = LOAD_OLD_CHAT_MESSAGES

  constructor(
    public payload: { case: HubtypeCase; messages: HubtypeMessage[] }
  ) {}
}

export class NewMessageReceivedAction implements Action {
  readonly type = NEW_MESSAGE_RECEIVED

  constructor(public payload: { case: HubtypeCase; message: HubtypeMessage }) {}
}

export class AddDraftMessageAction implements Action {
  readonly type = ADD_DRAFT_MESSAGE

  constructor(public payload: HubtypeMessage) {}
}

export class BanUserAction implements Action {
  readonly type = BAN_USER

  constructor(public payload: HubtypeUser) {}
}

export class SetArchiveCasesAction implements Action {
  readonly type = SET_ARCHIVE_CASES

  constructor(public payload: HubtypeCasePaginator) {}
}

export class SelectArchiveCaseAction implements Action {
  readonly type = SELECT_ARCHIVE_CASE

  constructor(public payload: HubtypeCase | null) {}
}

export class SetArchiveFilters implements Action {
  readonly type = SET_ARCHIVE_FILTERS

  constructor(public payload: DeskFilters) {}
}

export class UpdateArchiveMessagesAction implements Action {
  readonly type = UPDATE_ARCHIVE_MESSAGES

  constructor(
    public payload: { case?: HubtypeCase; messages: HubtypeMessage[] }
  ) {}
}

export class SetDashboardCasesAction implements Action {
  readonly type = SET_DASHBOARD_CASES

  constructor(public payload: HubtypeRealtimeCase[]) {}
}

export class AddDashboardCaseAction implements Action {
  readonly type = ADD_DASHBOARD_CASE

  constructor(public payload: HubtypeRealtimeCase) {}
}

export class SetReportingFilters implements Action {
  readonly type = SET_REPORTING_FILTERS

  constructor(public payload: DeskFilters) {}
}

export class UpdateCaseDescription implements Action {
  readonly type = UPDATE_CASE_DESCRIPTION
  constructor(public payload: HubtypeCase) {}
}

export class UpdateInboxCaseContactReasons implements Action {
  readonly type = UPDATE_INBOX_CASE_CONTACT_REASONS
  constructor(
    public payload: {
      case_id: string
      contact_reasons: ContactReasonWithCategory[]
    }
  ) {}
}
export class UpdateArchiveCaseContactReasons implements Action {
  readonly type = UPDATE_ARCHIVE_CASE_CONTACT_REASONS
  constructor(
    public payload: {
      case_id: string
      contact_reasons: ContactReasonWithCategory[]
    }
  ) {}
}

export class UpdateCasesWaitingListAction implements Action {
  readonly type = CASES_UPDATE_WAITING_LIST
  constructor(public payload: HubtypeCase[]) {}
}

export class UpdateCasesIdleListAction implements Action {
  readonly type = CASES_UPDATE_IDLE_LIST
  constructor(public payload: HubtypeCase[]) {}
}

export class UpdateCasesAttendingListAction implements Action {
  readonly type = CASES_UPDATE_ATTENDING_LIST
  constructor(public payload: HubtypeCase[]) {}
}

export class UpdateFilterCasesAction implements Action {
  readonly type = CASES_FILTER_UPDATE
  constructor(public payload: HubtypeCaseFilter) {}
}
export class UpdateSortCasesAction implements Action {
  readonly type = CASES_SORT_UPDATE
  constructor(public payload: HubtypeCaseSort) {}
}
export interface CaseEnduserTypingStatus {
  caseId: string
  isTyping: boolean
}
export class UpdateCaseEnduserTypingStatus implements Action {
  readonly type = UPDATE_CASE_ENDUSER_TYPING
  constructor(public payload: CaseEnduserTypingStatus) {}
}

export interface CaseChatOnline {
  caseId: string
  chatId: string
  isOnline: boolean
}
export class UpdateCaseChatOnline implements Action {
  readonly type = UPDATE_CASE_CHAT_ONLINE
  constructor(public payload: CaseChatOnline) {}
}

export class CloseCaseByEndUser implements Action {
  readonly type = CLOSE_CASE_BY_ENDUSER
  constructor(public payload: string) {}
}

export class MarkCaseToNotTranslate implements Action {
  readonly type = MARK_TO_NOT_TRANSLATE_CASE
  constructor(public payload: string) {}
}

export class MarkCaseToTranslate implements Action {
  readonly type = MARK_TO_TRANSLATE_CASE
  constructor(public payload: { caseId: string; userId: string }) {}
}
export class UpdateWaitingCasesCountAction implements Action {
  readonly type = CASES_UPDATE_WAITING_LIST_COUNT
  constructor(public payload: number) {}
}

export class UpdateCaseListsAction implements Action {
  readonly type = UPDATE_CASE_LIST
  constructor(public payload: { me: HubtypeUser; case: HubtypeCase }) {}
}
export class DeleteCasesByQueueAction implements Action {
  readonly type = DELETE_CASES_BY_QUEUE
  constructor(public payload: { queueId: string }) {}
}

export class CloseCaseChatWindowAction implements Action {
  readonly type = CLOSE_CASE_CHAT_WINDOW

  constructor(public payload: boolean) {}
}

export class SetSelectedMessagesAction implements Action {
  readonly type = SET_SELECTED_MESSAGES

  constructor(public payload: HubtypeMessage[]) {}
}

export class SetArchiveMessagesAction implements Action {
  readonly type = SET_ARCHIVE_MESSAGES

  constructor(public payload: HubtypeMessage[]) {}
}

export class ToggleNavbarCollapseAction implements Action {
  readonly type = TOGGLE_NAVBAR_COLLAPSE

  constructor() {}
}

export class SetNavbarFiltersAction implements Action {
  readonly type = SET_NAVBAR_FILTERS

  constructor(public payload: NavbarProjectQueueFilter[]) {}
}

export class DeleteNavbarFiltersAction implements Action {
  readonly type = DELETE_NAVBAR_FILTER

  constructor(public payload: { projectId: string }) {}
}
/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | SelectInboxCaseAction
  | SelectInboxProjectAction
  | SelectInboxQueueAction
  | AssignCaseMeAction
  | UnassignCaseAction
  | CaseIdleAction
  | CaseIdleOutAction
  | NewCaseAction
  | DiscardCaseAction
  | LoadOldChatMessagesAction
  | AddDraftMessageAction
  | ResolveCaseAction
  | BanUserAction
  | SetArchiveCasesAction
  | SetArchiveFilters
  | SelectArchiveCaseAction
  | UpdateArchiveMessagesAction
  | SetDashboardCasesAction
  | AddDashboardCaseAction
  | SetReportingFilters
  | UpdateCaseDescription
  | UpdateInboxCaseContactReasons
  | UpdateArchiveCaseContactReasons
  | UpdateCasesWaitingListAction
  | UpdateCasesIdleListAction
  | UpdateCasesAttendingListAction
  | UpdateFilterCasesAction
  | UpdateCaseEnduserTypingStatus
  | UpdateCaseChatOnline
  | CloseCaseByEndUser
  | UpdateWaitingCasesCountAction
  | UpdateSortCasesAction
  | NewMessageReceivedAction
  | MessageDeletedReceivedAction
  | NewACKMessageReceivedAction
  | UpdateCaseListsAction
  | DeleteCasesByQueueAction
  | CloseCaseChatWindowAction
  | SetSelectedMessagesAction
  | SetArchiveMessagesAction
  | ToggleNavbarCollapseAction
  | SetNavbarFiltersAction
  | DeleteNavbarFiltersAction
  | MarkCaseToNotTranslate
  | MarkCaseToTranslate
