import { Action } from '@ngrx/store'
import { HubtypeBot, HubtypeBotUpdate } from '../models/hubtype-bot'
import { Paginator } from '../models/paginator'

export const SET_BOTS = '[Bot] SetBots'
export const CREATE_BOT = '[Bot] CreateBot'
export const PATCH_DEBUG = '[Bot] PatchDebug'

export const SELECT_BOT = '[Bot] SelectBot'
export const DELETE_BOT = '[Bot] DeleteBot'
export const UPDATE_BOT = '[Bot] UpdateBot'

export const SET_UPDATES = '[Bot] SetUpdates'

/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful
 * type checking in reducer functions.
 *
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class SetBotsAction implements Action {
  readonly type = SET_BOTS

  constructor(public payload: HubtypeBot[]) {}
}

export class CreateBotAction implements Action {
  readonly type = CREATE_BOT

  constructor(public payload: HubtypeBot) {}
}

export class PatchBotDebug implements Action {
  readonly type = PATCH_DEBUG

  constructor(public payload: boolean) {}
}

export class SelectBotAction implements Action {
  readonly type = SELECT_BOT

  constructor(public payload: HubtypeBot) {}
}

export class DeleteBotAction implements Action {
  readonly type = DELETE_BOT

  constructor(public payload: HubtypeBot) {}
}

export class UpdateBotAction implements Action {
  readonly type = UPDATE_BOT

  constructor(public payload: HubtypeBot) {}
}

export class SetBotUpdatesAction implements Action {
  readonly type = SET_UPDATES

  constructor(public payload: Paginator<HubtypeBotUpdate>) {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions =
  | SetBotsAction
  | CreateBotAction
  | PatchBotDebug
  | SelectBotAction
  | DeleteBotAction
  | UpdateBotAction
  | SetBotUpdatesAction
