import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'

export const enum HtSelectButtonAppearance {
  OUTLINE = 'outline',
  MINIMAL = 'minimal',
}

export const enum HtSelectButtonSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

@Component({
  selector: '[ht-select-button]',
  templateUrl: './ht-select-button.component.html',
  styleUrls: ['./ht-select-button.component.scss'],
  host: {
    '[attr.aria-disabled]': 'isDisabled || isLoading',
  },
})
export class HtSelectButtonComponent implements OnInit, OnChanges {
  @HostBinding('class') modifierClasses = []

  @Input() isDisabled = false

  @Input() size = HtSelectButtonSize.MEDIUM
  @Input() appearance = HtSelectButtonAppearance.OUTLINE

  @HostBinding('class.ht-select-button--expanded') @Input() expanded = false

  @Input() loadingText = ''
  @HostBinding('class.ht-select-button--is-loading') @Input() isLoading = false

  @HostBinding('class.ht-select-button--full-width') @Input() fullWidth = false

  constructor() {}

  ngOnInit(): void {
    this.updateModifierClasses()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.size || changes.appearance) {
      this.updateModifierClasses()
    }
  }

  activateLoading(): void {
    this.isLoading = true
  }

  deactivateLoading(): void {
    this.isLoading = false
  }

  private updateModifierClasses(): void {
    this.modifierClasses = [
      'ht-select-button',
      `ht-select-button--${this.appearance}`,
      `ht-select-button--${this.size}`,
    ]
  }
}
