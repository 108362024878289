/* eslint-disable @typescript-eslint/naming-convention */
import { Any, JsonObject, JsonProperty } from 'json2typescript'
import moment from 'moment'
import { HubtypeCase } from './hubtype-case'

@JsonObject
export class HubtypeRealtimeCase {
  @JsonProperty('agent_ids', Any, true)
  agent_ids?: [string] = undefined
  @JsonProperty('agent_messages', Number, true)
  agent_messages?: number = undefined
  @JsonProperty('average_agent_speed_of_answer', Number, true)
  average_agent_speed_of_answer?: number = undefined
  @JsonProperty('average_enduser_speed_of_answer', Number, true)
  average_enduser_speed_of_answer?: number = undefined
  @JsonProperty('case_id', String, true)
  case_id?: string = undefined
  @JsonProperty('created_at', String, true)
  created_at?: string = undefined
  @JsonProperty('enduser_messages', Number, true)
  enduser_messages?: number = undefined
  @JsonProperty('endusers_count', Number, true)
  endusers_count?: number = undefined
  @JsonProperty('initial_agent_speed_of_answer', Number, true)
  initial_agent_speed_of_answer?: number = undefined
  @JsonProperty('initial_service_level', String, true)
  initial_service_level?: string = undefined
  @JsonProperty('provider', String, true)
  provider?: string = undefined
  @JsonProperty('queue_id', String, true)
  queue_id?: string = undefined
  @JsonProperty('resolution_status', String, true)
  resolution_status?: string = undefined
  @JsonProperty('resolution_time', Number, true)
  resolution_time?: number = undefined
  @JsonProperty('resolved_at', String, true)
  resolved_at?: string = undefined
  @JsonProperty('status', String, true)
  status?: string = undefined
  @JsonProperty('type', String, true)
  type?: string = undefined

  constructor() {}

  public get created_at_moment() {
    return moment(this.created_at)
  }

  public get resolved_at_moment() {
    return moment(this.resolved_at)
  }

  public get is_attending(): boolean {
    return this.status === HubtypeCase.STATUS_ATTENDING
  }

  public get is_waiting(): boolean {
    return this.status === HubtypeCase.STATUS_WAITING
  }

  public get is_idle(): boolean {
    return this.status === HubtypeCase.STATUS_IDLE
  }

  public get is_solved() {
    return this.status === HubtypeCase.STATUS_RESOLVED
  }
}
