import * as defaultProperties from './environment.common'

export const environment = {
  ...defaultProperties.environment,
  production: false,
  release_name: 'dev-dev-dev',
  baseURL: 'https://api.dev.dev.dev.hubtype.com',
  client_id: 'AcIVeUMYaqH6O17LiCzbR3phZcRmfxYQNYFMr3BI',
  pusher_api_prefix: 'dev-dev-dev',
  pusher_app_key: '99a92e08d7f1282b4544',
  pusher_cluster: 'eu',
  segment_token: 'hB6X7XwbTIm1HOKaAolks0k55RMP6rbh',
  facebook_app_id: '998299950252390',
  ssoUrl: null,
  // feature flags
  hardcodedInsightsFilters: true,
  flowbuilderIframeUrl: 'https://dev.flowbuilder.dev.hubtype.com',
}
