import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core'

export const enum HtButtonDSIntent {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
}

export const enum HtButtonDSAppearance {
  SOLID = 'solid',
  OUTLINE = 'outline',
  MINIMAL = 'minimal',
}

export const enum HtButtonDSSize {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

@Component({
  selector: '[ht-button-ds]',
  templateUrl: './ht-button-ds.component.html',
  styleUrls: ['./ht-button-ds.component.scss'],
  host: {
    '[attr.aria-disabled]': 'isDisabled || isLoading',
  },
})
export class HtButtonDSComponent implements OnInit, OnChanges {
  @HostBinding('class') modifierClasses = []

  @Input() isDisabled = false

  @Input() size = HtButtonDSSize.MEDIUM
  @Input() appearance = HtButtonDSAppearance.SOLID
  @Input() intent = HtButtonDSIntent.PRIMARY

  @Input() loadingText = ''
  @HostBinding('class.ht-button--is-loading') @Input() isLoading = false

  @HostBinding('class.ht-button--full-width') @Input() fullWidth = false

  constructor() {}

  ngOnInit(): void {
    this.updateModifierClasses()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.intent || changes.size || changes.appearance) {
      this.updateModifierClasses()
    }
  }

  activateLoading(): void {
    this.isLoading = true
  }

  deactivateLoading(): void {
    this.isLoading = false
  }

  private updateModifierClasses(): void {
    this.modifierClasses = [
      'ht-button',
      `ht-button--${this.intent}`,
      `ht-button--${this.appearance}`,
      `ht-button--${this.size}`,
    ]
  }
}

@Component({
  selector: '[ht-button-ds-primary]',
  templateUrl: './ht-button-ds.component.html',
  styleUrls: ['./ht-button-ds.component.scss'],
})
export class HtButtonDSPrimaryComponent extends HtButtonDSComponent {
  constructor() {
    super()
    this.intent = HtButtonDSIntent.PRIMARY
  }
}

@Component({
  selector: '[ht-button-ds-secondary]',
  templateUrl: './ht-button-ds.component.html',
  styleUrls: ['./ht-button-ds.component.scss'],
})
export class HtButtonDSSecondaryComponent extends HtButtonDSComponent {
  constructor() {
    super()
    this.intent = HtButtonDSIntent.SECONDARY
  }
}

@Component({
  selector: '[ht-button-ds-danger]',
  templateUrl: './ht-button-ds.component.html',
  styleUrls: ['./ht-button-ds.component.scss'],
})
export class HtButtonDSDangerComponent extends HtButtonDSComponent {
  constructor() {
    super()
    this.intent = HtButtonDSIntent.DANGER
  }
}
