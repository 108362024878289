import { Inject, Injectable } from '@angular/core'
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { HubtypeOrganization } from 'models/hubtype-organization'
import { HubtypeRealtimeCase } from 'models/hubtype-slas'
import { HubtypeTransfer } from 'models/hubtype-transfer'
import * as PusherTypes from 'pusher-js'
import Pusher from 'pusher-js/with-encryption'
import { Observable, Subject, defer, forkJoin, iif, of } from 'rxjs'
import { filter, finalize, first, switchMap, take, tap } from 'rxjs/operators'
import { assertDeserialize } from 'utils/json-utils'
import { queryStringToObject } from 'utils/string-utils'
import { environment } from '../../environments/environment'
import * as auth from '../actions/auth'
import * as deskAction from '../actions/desk'
import * as orgAction from '../actions/organization'
import {
  CASE_STATUS,
  HubtypeCase,
  ICaseAssigned,
  ICaseUpdated,
} from '../models/hubtype-case'
import { HubtypeMessage } from '../models/hubtype-message'
import { HubtypeProject } from '../models/hubtype-project'
import { HubtypeProviderAccount } from '../models/hubtype-provider-account'
import {
  HubtypeQueue,
  HubtypeQueueCounter,
  HubtypeQueueUpdated,
} from '../models/hubtype-queue'
import { HubtypeUser } from '../models/hubtype-user'
import * as fromRoot from '../reducers'
import { CaseList } from '../reducers/desk.state'
import { appOutOfFocus } from '../utils/dom-utils'
import { ConverterService } from './converter.service'
import { DesktopNotifications } from './desktop-notifications'
import { FeedbackService } from './feedback.service'
import { CaseService } from './hubtype-api/case.service'
import {
  HubtypeApiService,
  NO_VERSIONED_API,
} from './hubtype-api/hubtype-api.service'
import { ProjectService } from './hubtype-api/project.service'
import { QueueService } from './hubtype-api/queue.service'
import { UserService } from './hubtype-api/user.service'
import { LoggerService } from './logger.service'
import { SentryTags } from '../constants/sentry'

export enum ConnectionStatus {
  LOST = 'lost',
  RECONNECTED = 'reconnected',
}

// https://blog.pusher.com/real-time-apps-angular-2/

@Injectable()
export class PusherService {
  public organizationId: string
  public me: HubtypeUser
  public pusher
  public presenceChannel
  public dataChannel
  public dataSLAChannel
  public userChannel
  public pusherConnected = true
  public dialogRef: MatDialogRef<any>
  connectionCheckTimeout = 2000
  public status: Subject<ConnectionStatus> = new Subject()

  constructor(
    private store: Store<fromRoot.State>,
    public dialog: MatDialog,
    @Inject('projectService')
    private projectService: ProjectService,
    @Inject('queueService') private queueService: QueueService,
    @Inject('caseService') private caseService: CaseService,
    @Inject('userService') private userService: UserService,
    @Inject('desktopNotifications')
    private desktopNotification: DesktopNotifications,
    @Inject('convertService') private convertService: ConverterService,
    private feedbackService: FeedbackService,
    @Inject('apiService') private apiService: HubtypeApiService,
    private router: Router,
    private loggerService: LoggerService
  ) {
    this.store.select(fromRoot.getMeState).subscribe(u => (this.me = u))
    this.disconnectPusherWhenLogout()
    this.connectPusherWhenLoginOrRefresh()
  }

  connectPusherWhenLoginOrRefresh() {
    this.store
      .select(fromRoot.isLoggedIn)
      .pipe(filter(isLogged => isLogged === true))
      .subscribe(() => {
        this.store
          .select(fromRoot.getOrganization)
          .pipe(first(Boolean))
          .subscribe((org: HubtypeOrganization) => {
            this.organizationId = org.id
            this.connect()
          })
      })
  }

  disconnectPusherWhenLogout() {
    this.store
      .select(fromRoot.isLoggedIn)
      .pipe(filter(isLogged => !isLogged))
      .subscribe(() => {
        this.pusher?.disconnect()
      })
  }

  connectSLAs() {
    this.dataSLAChannel = this.pusher?.subscribe(
      this.dataSLAChannelName(this.organizationId)
    )
    this.dataSLAChannel?.bind('new_sla_item', data => {
      this.store.dispatch(
        new deskAction.AddDashboardCaseAction(
          this.convertService.jsonConvert.deserializeObject(
            data.message,
            HubtypeRealtimeCase
          )
        )
      )
    })

    this.dataSLAChannel?.bind('queue_counters_update', data =>
      this.handleQueueCountersUpdate(data)
    )
  }

  disconnectSLAs() {
    if (this.pusher) {
      this.pusher.unsubscribe(this.dataSLAChannelName(this.organizationId))
    }
  }

  disconnect() {
    if (this.presenceChannel) {
      this.pusher.unsubscribe(this.presenceChannelName(this.organizationId))
    }
    if (this.dataChannel) {
      this.pusher.unsubscribe(this.dataChannelName(this.organizationId))
    }
    if (this.userChannel) {
      this.pusher.unsubscribe(this.getUserChannelName(this.me.id))
    }
  }

  connect() {
    this.initializePusher()
    this.disconnect()

    this.presenceChannel = this.pusher.subscribe(
      this.presenceChannelName(this.organizationId)
    )
    this.dataChannel = this.pusher.subscribe(
      this.dataChannelName(this.organizationId)
    )
    this.userChannel = this.pusher.subscribe(
      this.getUserChannelName(this.me.id)
    )

    this.dataChannel.bind('pusher:subscription_succeeded', data => {
      this.store.dispatch(new auth.SetPusherConnectionStatusAction(true))
    })

    this.pusher.connection.bind('state_change', this.pusherStatusManager)

    // -----------------PUSHER EVENTS-----------------//
    // Start User Channel Events
    this.userChannel.bind('start_typing', data => {
      this.handleEnduserTypingEvent(data?.message?.case_id, true)
    })
    this.userChannel.bind('end_typing', data => {
      this.handleEnduserTypingEvent(data?.message?.case_id, false)
    })

    this.userChannel.bind('case_chat_online', this.caseChatOnlineManager)
    this.dataChannel.bind('case_chat_online', this.caseChatOnlineManager)

    this.userChannel.bind('new_message', this.newMessageManager)
    this.userChannel.bind('message_deleted', this.messageDeletedReceivedManager)

    this.userChannel.bind(
      'message_ack_received',
      this.messageAckReceivedManager
    )

    this.dataChannel.bind('user_activated', this.userCreated)
    this.dataChannel.bind('user_created', this.userCreated)
    this.dataChannel.bind('user_deactivated', this.userDeactivated)

    // End User Channel Events

    this.dataChannel.bind('project_created', data => {
      if (this.me.is_admin) {
        const p = this.convertService.jsonConvert.deserializeObject(
          data.message,
          HubtypeProject
        )
        this.store.dispatch(new orgAction.AddProjectAction(p))
      }
    })

    this.dataChannel.bind('project_deleted', data => {
      const p = this.convertService.jsonConvert.deserializeObject(
        data.message,
        HubtypeProject
      )
      this.store.dispatch(new orgAction.DeleteProjectAction(p))
    })

    this.dataChannel.bind('project_updated', data => {
      const p = this.convertService.jsonConvert.deserializeObject(
        data.message,
        HubtypeProject
      )
      this.store
        .select(fromRoot.getProjects)
        .pipe(
          first(),
          filter(projects => projects.some(project => project.id === p.id))
        )
        .subscribe(projects => {
          this.store.dispatch(new orgAction.UpdateProjectAction(p))
        })
    })

    this.dataChannel.bind('queue_created', data => {
      const q = this.convertService.jsonConvert.deserializeObject(
        data.message,
        HubtypeQueue
      )
      this.store.dispatch(new orgAction.AddQueueAction(q))
    })

    this.dataChannel.bind('queue_updated', this.updateQueue)

    this.dataChannel.bind('queue_deleted', data => {
      const q = this.convertService.jsonConvert.deserializeObject(
        data.message,
        HubtypeQueue
      )
      this.store.dispatch(new orgAction.DeleteQueueAction(q))
    })

    this.dataChannel.bind('case_created_v2', this.handleCaseCreated)

    this.dataChannel.bind('case_assigned', data => {
      const caseInfo: ICaseAssigned = data?.message?.case
      if (!caseInfo) {
        this.logErrorInSentry(
          'Pusher: Unable to assign case',
          'Unable to assign case, the case information is missing.',
          { data }
        )
        return
      }

      if (!this.me.hasQueuePermission(caseInfo.queue_id)) {
        return
      }
      this.caseAssignHandler(caseInfo)
    })

    this.dataChannel.bind('case_changed_status', data => {
      const updatedCase: ICaseUpdated = data?.message?.case

      if (!updatedCase.id) {
        this.logErrorInSentry(
          'Pusher: Unable to change case status',
          'Unable to change case status',
          { data }
        )
        return
      }
      if (
        !this.me.hasQueuePermission(updatedCase.queue_id) ||
        this.shouldIgnoreByStatus(updatedCase, data.message)
      ) {
        return
      }
      this.caseService
        .getCase(updatedCase.id)
        .pipe(first())
        .subscribe(c => {
          if (!c) {
            this.logErrorInSentry(
              'Pusher: Unable to change case status',
              'Unable to change case status, the case with ID does not exist.',
              { updatedCase }
            )
            return
          }
          c.setUpdatedFields(updatedCase)
          this.caseUpdateStatus(
            c,
            data.message.prev_status,
            data.message.next_status
          )
        })
    })

    this.dataChannel.bind('case_transferred', this.caseTransferManager)

    this.dataChannel.bind(
      'case_contact_reasons_changed',
      this.updatedCaseContactReasons
    )

    this.dataChannel.bind('new_message', this.newMessageManager)

    this.dataChannel.bind('manager_added', data => {
      const projectId = data.message.project.id
      const userId = data.message.manager_id

      this.addManager(projectId, userId)
    })

    this.dataChannel.bind('manager_deleted', data => {
      const projectId = data.message.project.id
      const userId = data.message.manager_id
      this.deleteManager(projectId, userId)
    })

    this.dataChannel.bind('agent_assigned', data => {
      const queueId = data?.message?.queue_id
      const agentId = data?.message?.agent_id
      if (!queueId) {
        this.logErrorInSentry(
          'Pusher: Unable to assign new agent to the queue',
          'Unable to assign new agent to the queue, the queue ID is missing.',
          { data }
        )
        return
      }
      if (!agentId) {
        this.logErrorInSentry(
          'Pusher: Unable to assign new agent to the queue',
          'Unable to assign new agent to the queue, the agent ID is missing.',
          { data }
        )
        return
      }
      this.agentAssigned(queueId, agentId)
    })

    this.dataChannel.bind('agent_unassigned', data => {
      const queueId = data?.message?.queue_id
      const agentId = data?.message?.agent_id
      if (!queueId) {
        this.logErrorInSentry(
          'Pusher: Unable to assign new agent to the queue',
          'Unable to assign new agent to the queue, the queue ID is missing.',
          { data }
        )
        return
      }
      if (!agentId) {
        this.logErrorInSentry(
          'Pusher: Unable to assign new agent to the queue',
          'Unable to assign new agent to the queue, the agent ID is missing.',
          { data }
        )
        return
      }

      this.agentUnassigned(queueId, agentId)
    })

    this.dataChannel.bind('agent_changed_status', data => {
      const agent = this.convertService.jsonConvert.deserializeObject(
        data.message.user,
        HubtypeUser
      )
      if (agent.id === this.me.id) {
        this.store.dispatch(new auth.UpdateMeStatus(agent.status))
      }
      this.store.dispatch(new orgAction.ChangeStatusAgentAction(agent))
    })

    this.dataChannel.bind('provider_account_created', data => {
      const providerAccount = this.convertService.jsonConvert.deserializeObject(
        data.message,
        HubtypeProviderAccount
      )
      this.store.dispatch(
        new orgAction.AddProviderAccountAction(providerAccount)
      )
    })

    this.dataChannel.bind('provider_account_updated', data => {
      const providerAccount = this.convertService.jsonConvert.deserializeObject(
        data.message,
        HubtypeProviderAccount
      )
      this.store.dispatch(
        new orgAction.UpdateProviderAccountAction(providerAccount)
      )
    })

    this.dataChannel.bind('provider_account_deleted', data => {
      const providerAccount = this.convertService.jsonConvert.deserializeObject(
        data.message,
        HubtypeProviderAccount
      )
      this.store.dispatch(
        new orgAction.DeleteProviderAccountAction(providerAccount)
      )
    })

    // Case closed by the enduser at this moment just raised when apple user deletes the conversation
    this.dataChannel.bind('case_closed', data => {
      this.caseService.closeTicketByEnduser(data?.message?.case_id)
    })
  }

  userCreated = data => {
    const userId = data.message.user_id

    this.userService.fetchUser(userId).subscribe(user => {
      this.store.dispatch(new orgAction.AddUserAction(user))
    })
  }

  userDeactivated = data => {
    const userId = data.message.user_id

    this.userService
      .getUser(userId)
      .pipe(first())
      .subscribe({
        next: user => {
          this.store.dispatch(new orgAction.DeleteUserAction(user))
          if (userId == this.me.id) {
            this.logoutCurrentUser()
          }
        },
      })
  }

  private logoutCurrentUser() {
    this.userService
      .setOffline()
      .pipe(
        first(),
        finalize(() => {
          this.store.dispatch(new auth.LogoutAction())
          this.router.navigate(['/sign-in'])
        })
      )
      .subscribe()
  }

  public pusherStatusManager = states => {
    let connectionStatus: ConnectionStatus = null

    if (states.previous === 'connected' && states.current === 'connecting') {
      this.pusherConnected = false
      connectionStatus = ConnectionStatus.LOST
    }

    if (
      !this.pusherConnected &&
      states.previous === 'connecting' &&
      states.current === 'connected'
    ) {
      this.pusherConnected = true
      connectionStatus = ConnectionStatus.RECONNECTED
    }

    if (connectionStatus) {
      this.status.next(connectionStatus)
    }
  }

  caseChatOnlineManager = data => {
    this.store.dispatch(
      new deskAction.UpdateCaseChatOnline({
        caseId: data?.message?.case_id,
        chatId: data?.message?.chat_id,
        isOnline: data?.message?.is_online,
      })
    )
  }

  updatedCaseContactReasons = data => {
    const { case_id, new_contact_reasons } = data.message
    forkJoin([
      this.store.select(fromRoot.getCase(case_id)).pipe(first()),
      this.store.select(fromRoot.getArchiveCase(case_id)).pipe(first()),
    ]).subscribe({
      next: ([inboxCase, archiveCase]) => {
        if (inboxCase) {
          this.store.dispatch(
            new deskAction.UpdateInboxCaseContactReasons({
              case_id,
              contact_reasons: new_contact_reasons,
            })
          )
        }
        if (archiveCase) {
          this.store.dispatch(
            new deskAction.UpdateArchiveCaseContactReasons({
              case_id,
              contact_reasons: new_contact_reasons,
            })
          )
        }
      },
    })
  }

  handleQueueCountersUpdate = data => {
    if (!this.me.hasQueuePermission(data.message.id)) {
      return
    }
    const queueCounters = this.convertService.jsonConvert.deserializeObject(
      data.message,
      HubtypeQueueCounter
    )
    this.store.dispatch(new orgAction.UpdateQueueCounters(queueCounters))
  }

  handleCaseCreated = data => {
    if (
      !this.shouldProcessCaseCreated(
        data.message.queue_id,
        data.message.status,
        data.message.assigned_to_id
      )
    ) {
      return
    }

    this.caseService
      .getCase(data.message.id)
      .pipe(first())
      .subscribe(backend_case => {
        if (
          !this.shouldProcessCaseCreated(
            backend_case.queue_id,
            backend_case.status,
            backend_case.assigned_to?.id
          )
        ) {
          return
        }
        this.notifyDesktopNewCaseCreated(backend_case)
        this.store.dispatch(new deskAction.NewCaseAction(backend_case))
      })
  }

  shouldProcessCaseCreated(
    queueId: string,
    caseStatus: string,
    assignedToId: string
  ) {
    if (!this.me.hasQueuePermission(queueId)) {
      return false
    }

    const isNotWaiting = caseStatus != CASE_STATUS.STATUS_WAITING
    const isNotAssignedToMe = assignedToId !== this.me.id
    if (isNotWaiting && isNotAssignedToMe) {
      return false
    }
    return true
  }

  newMessageManager = data => {
    if (!this.me.hasQueuePermission(data.message.queue_id)) {
      return
    }

    let caseInf: HubtypeCase
    const m = this.convertService.jsonConvert.deserializeObject(
      data.message,
      HubtypeMessage
    )
    this.store
      .select(fromRoot.getCase(m.case_id))
      .pipe(take(1), filter(Boolean))
      .subscribe((c: HubtypeCase) => {
        this.convertService.jsonConvert.deserializeObject(c, HubtypeCase)
        this.store.dispatch(
          new deskAction.NewMessageReceivedAction({
            case: c,
            message: m,
          })
        )
        caseInf = c
      })
    try {
      if (
        appOutOfFocus() &&
        this.shouldNotifyNewCaseWhenNewMessage(caseInf, m)
      ) {
        this.desktopNotification.notify(
          '',
          'New case received',
          this.me.notifications_duration,
          m.case_id
        )
      } else if (appOutOfFocus() && this.shouldNotifyNewMessage(caseInf, m)) {
        this.desktopNotification.notify(
          m.text,
          caseInf.chat.enduser.name
            ? caseInf.chat.enduser.name
            : caseInf.chat.name + ' :',
          this.me.notifications_duration,
          m.case_id
        )
      }
    } catch (e) {
      console.log('error in new message in pusher', e)
    }
  }

  messageDeletedReceivedManager = data => {
    const message = this.convertService.jsonConvert.deserializeObject(
      data.message,
      HubtypeMessage
    )
    this.store.dispatch(new deskAction.MessageDeletedReceivedAction(message))
  }

  messageAckReceivedManager = data => {
    const message = this.convertService.jsonConvert.deserializeObject(
      data.message,
      HubtypeMessage
    )
    this.store.dispatch(new deskAction.NewACKMessageReceivedAction(message))
  }

  private notifyDesktopNewCaseCreated(backend_case: HubtypeCase) {
    if (appOutOfFocus() && this.shouldNotifyNewCaseWhenNewCase(backend_case)) {
      let message = this.createNewCaseNotificationMessage(backend_case)
      this.desktopNotification.notify(
        '',
        message,
        this.me.notifications_duration,
        backend_case.id
      )
    }
  }

  private createNewCaseNotificationMessage(backend_case: HubtypeCase) {
    let message = 'New case received'
    try {
      if (backend_case.chat.enduser.name) {
        message += ' (' + backend_case.chat.enduser.name + ')'
      }
    } catch (e) {}
    return message
  }

  pusherApiPrefix(): string {
    // TODO on some configurations you may need to get the lowercase
    // add a warning when startup if it contains uppercae?
    return environment.pusher_api_prefix
  }

  presenceChannelName(orgId) {
    return `presence-${this.pusherApiPrefix()}_organization_${orgId}`
  }

  dataChannelName(orgId) {
    return `private-${this.pusherApiPrefix()}_organization_${orgId}`
  }

  dataSLAChannelName(orgId) {
    return `private-${this.pusherApiPrefix()}_organization_${orgId}_slas`
  }

  getUserChannelName(userId: string) {
    return `private-${this.pusherApiPrefix()}_hubtypeuser_${userId}`
  }

  shouldNotifyNewCaseWhenNewMessage(
    c: HubtypeCase,
    m: HubtypeMessage
  ): boolean {
    return (
      Boolean(c) &&
      Boolean(m) &&
      !HubtypeCase.isAssigned(c) &&
      this.me.notifications_new_case &&
      m.isChangeOfStateMessage('status_attending', 'status_waiting')
    )
  }

  shouldNotifyNewCaseWhenNewCase(c: HubtypeCase): boolean {
    return (
      Boolean(c) &&
      this.me?.notifications_new_case &&
      c?.chat?.enduser &&
      (!HubtypeCase.isAssigned(c) || c.isAssignedTo(this.me?.id))
    )
  }

  shouldNotifyNewMessage(c: HubtypeCase, m: HubtypeMessage): boolean {
    return (
      Boolean(m?.is_enduser) &&
      HubtypeCase.isAssigned(c) &&
      c.isAssignedTo(this.me.id) &&
      this.me.notifications_new_message
    )
  }

  showTransferDesktopNotification(message: string, caseId: string) {
    if (appOutOfFocus() && this.me.notifications_new_case) {
      this.desktopNotification.notify(
        message,
        'Case Transferred',
        this.me.notifications_duration,
        caseId
      )
    }
  }

  handleTransferNotifications(transferData: HubtypeTransfer) {
    if (this.me.id === transferData.executor_user?.id) {
      this.feedbackService.success('The case was transferred correctly')
    } else if (this.me.id === transferData.prev_user?.id) {
      if (transferData.prev_user?.id === transferData.next_user?.id) {
        this.feedbackService.info(
          `${transferData.executor_user?.name} has moved one of your cases to ${transferData?.next_queue?.project} | ${transferData?.next_queue?.name}`
        )
        this.showTransferDesktopNotification(
          'One of your cases has been transferred',
          transferData.case.id
        )
      } else {
        this.feedbackService.info(
          `${transferData.executor_user?.name} has transferred one of your cases`
        )
        this.showTransferDesktopNotification(
          'One of your cases has been transferred',
          null
        )
      }
    } else if (this.me.id === transferData.next_user?.id) {
      this.feedbackService.info(
        `${transferData.executor_user?.name} has transferred one case to you`
      )
      this.showTransferDesktopNotification(
        'A case has been transferred to you',
        transferData.case.id
      )
    } else if (
      !this.me.hasQueuePermission(transferData.prev_queue?.id) &&
      this.me.hasQueuePermission(transferData.next_queue?.id) &&
      !transferData.next_user?.id
    ) {
      this.showTransferDesktopNotification(
        'You have a new transferred case',
        transferData.case.id
      )
    }
  }

  caseAssignHandler = (caseInfo: ICaseAssigned) => {
    this.caseService
      .getCase(caseInfo.id, true)
      .pipe(first())
      .subscribe(c => {
        this.store.dispatch(
          new deskAction.UpdateCaseListsAction({ me: this.me, case: c })
        )
      })
  }

  caseUpdateStatus(
    updatedCase: HubtypeCase,
    prevStatus: string,
    nextStatus: string
  ) {
    if (
      prevStatus === HubtypeCase.STATUS_ATTENDING &&
      nextStatus === HubtypeCase.STATUS_IDLE
    ) {
      this.store.dispatch(new deskAction.CaseIdleAction(updatedCase))
    }
    if (
      prevStatus === HubtypeCase.STATUS_IDLE &&
      nextStatus === HubtypeCase.STATUS_ATTENDING
    ) {
      this.store.dispatch(new deskAction.CaseIdleOutAction(updatedCase))
    }
    if (
      (prevStatus === HubtypeCase.STATUS_IDLE ||
        prevStatus === HubtypeCase.STATUS_ATTENDING) &&
      nextStatus === HubtypeCase.STATUS_RESOLVED
    ) {
      this.store.dispatch(new deskAction.ResolveCaseAction(updatedCase))
    }
    if (
      prevStatus === HubtypeCase.STATUS_WAITING &&
      nextStatus === HubtypeCase.STATUS_RESOLVED
    ) {
      this.store.dispatch(new deskAction.DiscardCaseAction(updatedCase))
    }
    if (
      prevStatus === HubtypeCase.STATUS_RESOLVED &&
      nextStatus === HubtypeCase.STATUS_WAITING
    ) {
      this.store.dispatch(new deskAction.NewCaseAction(updatedCase))
    }
    if (
      prevStatus === HubtypeCase.STATUS_RESOLVED &&
      nextStatus === HubtypeCase.STATUS_ATTENDING
    ) {
      this.store.select(fromRoot.getMeId).subscribe(meId => {
        if (updatedCase.assigned_to.id === meId) {
          this.store.dispatch(new deskAction.NewCaseAction(updatedCase))
        }
      })
    }
  }

  caseTransferManager = (data: { message: HubtypeTransfer }) => {
    const transferData = assertDeserialize(data.message, HubtypeTransfer)

    this.handleTransferNotifications(transferData)

    this.store.dispatch(
      new deskAction.UpdateCaseListsAction({
        me: this.me,
        case: transferData.case,
      })
    )
  }

  updateQueue = (data: { message: HubtypeQueueUpdated }) => {
    const updatedQueueData: HubtypeQueueUpdated = data.message
    if (!updatedQueueData.id) {
      this.logErrorInSentry(
        'Pusher: Unable to update the queue',
        'Unable to update the queue, the queue ID is missing',
        { data }
      )
      return
    }

    if (!this.me.hasQueuePermission(updatedQueueData.id)) {
      return
    }
    this.store
      .select(fromRoot.getQueueById(updatedQueueData.id))
      .pipe(first())
      .subscribe(queue => {
        if (!queue) {
          return
        }
        queue.setUpdatedFields(updatedQueueData)
        this.store.dispatch(new orgAction.UpdateQueueAction(queue))
      })
  }

  handleEnduserTypingEvent(caseId: string, isTyping: boolean) {
    this.caseService
      .getStoredCase(caseId)
      .pipe(first())
      .subscribe((storedCase: HubtypeCase) => {
        if (storedCase?.id === caseId) {
          this.caseService.updateEndUserTyping(caseId, isTyping)
        }
      })
  }

  shouldIgnoreByStatus(updatedCase, message) {
    return (
      (message.prev_status === 'status_attending' &&
        message.next_status === 'status_idle' &&
        updatedCase.assigned_to?.id !== this.me.id) ||
      (message.prev_status === 'status_idle' &&
        message.next_status === 'status_attending' &&
        updatedCase.assigned_to?.id !== this.me.id)
    )
  }

  //1. If I don't have this queue in my store and I'm not this agent, return null. Not need anything to update
  //2. If I don't have this queue in my store and I'm this agent, find and download the queue from backend .Jump to step 4
  //3. If I have this queue in my store and I'm not this agent, refresh queue's agent
  //4. If I have this queue in my store and I'm this agent, download project if needed and load cases of the queue
  agentAssigned(queueId: string, agentId: string) {
    this.store
      .select(fromRoot.getUser(agentId))
      .pipe(take(1))
      .subscribe(storeUser => {
        if (!storeUser) {
          this.logErrorInSentry(
            'Pusher: Unable to assign new agent to the queue',
            'Unable to assign new agent to the queue, the agent ID not found',
            { queueId, agentId }
          )
          return
        }
        //Queue synchronized
        this.getAndSyncQueue(queueId, this.me.id === agentId)
          .pipe(filter(queue => Boolean(queue)))
          .subscribe(queue => {
            //Update agents
            this.store.dispatch(
              new orgAction.AddAgentAction({
                user: this.convertService.jsonConvert.deserializeObject(
                  storeUser,
                  HubtypeUser
                ),
                queue: this.convertService.jsonConvert.deserializeObject(
                  queue,
                  HubtypeQueue
                ),
              })
            )

            if (this.me.id === agentId) {
              //Project sync
              this.getAndSyncProject(queue.project_id, true).subscribe()

              //Load cases
              this.loadAllCases()
            }
          })
      })
  }

  agentUnassigned(queueId: string, agentId: string) {
    this.store
      .select(fromRoot.getUser(agentId))
      .pipe(take(1))
      .subscribe(u => {
        if (!u) {
          this.logErrorInSentry(
            'Pusher: Unable to unassign agent from the queue',
            'Unable to unassign agent from the queue, the agent ID not found',
            { queueId, agentId }
          )
          return
        }
        this.store
          .select(fromRoot.getQueueByIdFromOrg(queueId))
          .pipe(
            take(1),
            filter(queue => Boolean(queue))
          )
          .subscribe(q => {
            const queue = this.convertService.jsonConvert.deserializeObject(
              q,
              HubtypeQueue
            )
            this.store.dispatch(
              new orgAction.DeleteAgentAction({
                user: this.convertService.jsonConvert.deserializeObject(
                  u,
                  HubtypeUser
                ),
                queue,
              })
            )
            if (agentId === this.me.id) {
              //Update queues
              this.store.dispatch(new orgAction.DeleteQueueAction(queue))
              //Remove waiting cases
              this.store.dispatch(
                new deskAction.DeleteCasesByQueueAction({
                  queueId: q.id,
                })
              )
            }
          })
      })
  }

  //1. If I don't have this project in my store and I'm not the userId, ignore.
  //2. If I don't have this project in my store and I'm the userId, find and download the project from backend .Jump to step 4
  //3. If I have this project in my store and I'm not the userId, dispach addManager in store
  //4. If I have this project in my store and I'm the userId, add queues to store and download cases from backend
  addManager(projectId: string, userId: string) {
    this.store
      .select(fromRoot.getUser(userId))
      .pipe(
        take(1),
        filter(user => Boolean(user))
      )
      .subscribe(u => {
        this.getAndSyncProject(projectId, userId === this.me.id)
          .pipe(filter(project => Boolean(project)))
          .subscribe(project => {
            this.store.dispatch(
              new orgAction.AddManagerAction({
                user: this.convertService.jsonConvert.deserializeObject(
                  u,
                  HubtypeUser
                ),
                project: this.convertService.jsonConvert.deserializeObject(
                  project,
                  HubtypeProject
                ),
              })
            )
            if (this.me.id === userId) {
              //Update queues
              project.queues?.forEach(queue => {
                this.store.dispatch(new orgAction.AddQueueAction(queue))
              })

              this.loadAllCases()
            }
          })
      })
  }

  deleteManager(projectId: string, userId: string) {
    this.store
      .select(fromRoot.getUser(userId))
      .pipe(
        take(1),
        filter(user => Boolean(user))
      )
      .subscribe(u => {
        this.store
          .select(fromRoot.getProject(projectId))
          .pipe(
            take(1),
            filter(project => Boolean(project))
          )
          .subscribe(project => {
            this.store.dispatch(
              new orgAction.DeleteManagerAction({
                user: this.convertService.jsonConvert.deserializeObject(
                  u,
                  HubtypeUser
                ),
                project: this.convertService.jsonConvert.deserializeObject(
                  project,
                  HubtypeProject
                ),
              })
            )
            if (userId === this.me.id) {
              //Update queues
              project.queues?.forEach(queue => {
                this.store.dispatch(new orgAction.DeleteQueueAction(queue))
                this.store.dispatch(
                  new deskAction.DeleteCasesByQueueAction({
                    queueId: queue.id,
                  })
                )
              })
              this.store.dispatch(new orgAction.DeleteProjectAction(project))
            }
          })
      })
  }

  getAndSyncProject(projectId: string, forceSync: boolean) {
    return this.store.select(fromRoot.getProject(projectId)).pipe(
      take(1),
      switchMap(project =>
        iif(
          () => Boolean(project),
          of(project),
          iif(
            // why use defer => https://stackoverflow.com/questions/54097971/rxjs-iif-arguments-are-called-when-shouldnt
            () => forceSync === true,
            defer(() =>
              this.projectService.get(projectId, true).pipe(
                take(1),
                tap(projectFromBackend => {
                  this.store.dispatch(
                    new orgAction.AddProjectAction(projectFromBackend)
                  )
                })
              )
            ),
            defer(() => of(null))
          )
        )
      )
    )
  }

  getAndSyncQueue(queueId, forceSync): Observable<HubtypeQueue> {
    return this.store.select(fromRoot.getQueueByIdFromOrg(queueId)).pipe(
      take(1),
      switchMap(queue =>
        iif(
          () => Boolean(queue),
          of(queue),
          iif(
            // why use defer => https://stackoverflow.com/questions/54097971/rxjs-iif-arguments-are-called-when-shouldnt
            () => forceSync === true,
            defer(() =>
              this.queueService.get(queueId).pipe(
                take(1),
                tap(queueService => {
                  this.store.dispatch(
                    new orgAction.AddQueueAction(queueService)
                  )
                })
              )
            ),
            defer(() => of(null))
          )
        )
      )
    )
  }

  loadAllCases() {
    this.caseService.loadCasesInParallel(CaseList.ATTENDING)
    this.caseService.loadCasesInParallel(CaseList.IDLE)
    this.caseService.loadCasesInParallel(CaseList.WAITING)
  }

  private initializePusher() {
    // Uncomment to show pusher logs on console (very verbose)
    // --------------------------------------------------------
    // Pusher.log = message => {
    //   console.log(new Date().toISOString(), message)
    // }
    // --------------------------------------------------------

    const PING_PONG_INTERVAL_MILISECONDS = 1 * 1000 // Every x miliseconds pusher verifies if the connection still alive.
    const PONG_TIMEOUT_MILISECONDS = 30 * 1000 // The max timeout waiting for the pong response from the server to identify the connection as broken.
    const config: PusherTypes.Options = {
      cluster: environment.pusher_cluster,
      // @ts-ignore
      authTransport: 'customPusherAuthorizer',
      activityTimeout: PING_PONG_INTERVAL_MILISECONDS,
      pongTimeout: PONG_TIMEOUT_MILISECONDS,
    }
    if (environment['pusher_host']) {
      config.httpHost = environment['pusher_host']
      config.httpPort = environment['pusher_port']
      config.httpsPort = environment['pusher_port_ssl']
      config.wsHost = environment['pusher_host']
      config.wsPort = environment['pusher_port']
      config.wssPort = environment['pusher_port_ssl']
      config.disableStats = true
    }

    this.setCustomPusherAuthorizer()
    this.pusher = new Pusher(environment.pusher_app_key, config)
  }

  private setCustomPusherAuthorizer() {
    // To be able to handle correctly the auth token of the pusher authorization request with the angular interceptor we decided to use a custom pusher Authorizer.
    // https://support.pusher.com/hc/en-us/articles/4412502563473-Providing-A-Custom-Channels-Authoriser
    const supportedAuthorizers = Pusher.Runtime.getAuthorizers()
    supportedAuthorizers.customPusherAuthorizer = (
      context,
      params,
      options,
      authorizationType,
      callback
    ) => {
      this.authorizeChannel(queryStringToObject(params)).subscribe({
        next: authorization => callback(false, authorization),
        error: error => callback(true, error),
      })
    }
    Pusher.Runtime.getAuthorizers = () => supportedAuthorizers
  }

  private authorizeChannel(params: object) {
    return this.apiService
      .post('/pusher/auth', params, null, NO_VERSIONED_API)
      .pipe(first())
  }

  private logErrorInSentry(
    errorName: string,
    errorMessage: string,
    extra: object
  ) {
    this.loggerService.logRegularError(errorName, errorMessage, {
      tags: { [SentryTags.PUSHER_ERROR]: true },
      extra,
    })
  }
}
