<ng-container *ngIf="row">
  <!--Checkbox-->
  <ng-container *ngIf="tableConfig.expandable">
    <td role="cell" class="td-expand">
      <i
        *ngIf="row.expandable"
        class="fa-solid fa-caret-right"
        [ngClass]="{ 'fa-rotate-90': row.isExpanded }"
      ></i>
    </td>
  </ng-container>
  <ng-container *ngIf="tableConfig.selectable">
    <td role="cell" class="td-select">
      <ht-checkbox
        [formControl]="selectFormControl"
        tabIndex="-1"
      ></ht-checkbox>
    </td>
  </ng-container>

  <ng-container *ngFor="let column of tableConfig.columns">
    <td
      table-column
      *ngIf="!column.isDeactivated"
      [column]="column"
      [row]="row"
      [isEditing]="isEditing"
      [ngClass]="column.class"
      [ngStyle]="{
        width: column.width ? column.width : 'auto'
      }"
    >
    </td>
  </ng-container>

  <td *ngIf="rowActions?.length" class="td-container-actions">
    <div *ngIf="!isEditing">
      <ng-container *ngFor="let action of rowActions">
        <a
          tabindex="0"
          (click)="clickAction($event, action)"
          (keydown.enter)="clickAction($event, action)"
          (keydown.space)="clickAction($event, action)"
          [attr.aria-label]="action.label"
          [attr.title]="action.label"
          *ngIf="!action?.isDeactivated"
          [class.has-label]="action.hasVisibleLabel"
        >
          <i class="fa-solid" [ngClass]="action.icon"></i>
          <span *ngIf="action.hasVisibleLabel">{{ action.label }}</span>
        </a>
      </ng-container>
    </div>
    <div *ngIf="isEditing">
      <button
        ht-button-ds-primary
        (click)="onSaveOnRow(index); $event.stopPropagation()"
      >
        Save
      </button>

      <button
        ht-button-ds-secondary
        (click)="onRestoreOnRow(index); $event.stopPropagation()"
      >
        Cancel
      </button>
    </div>
  </td>
</ng-container>
