import { JsonConvert, ValueCheckingMode } from 'json2typescript'
import * as auth from '../actions/auth'
import * as bot from '../actions/bot'
import * as organization from '../actions/organization'
import {
  HubtypeBot,
  HubtypeBotUpdate,
  HubtypeBotUpdatePaginator,
} from '../models/hubtype-bot'
import { HubtypeProviderAccount } from '../models/hubtype-provider-account'
import { Paginator } from '../models/paginator'

const jsonConverter: JsonConvert = new JsonConvert()
jsonConverter.valueCheckingMode = ValueCheckingMode.ALLOW_NULL

export interface State {
  bots: HubtypeBot[]
  selectedBot: HubtypeBot | null
  selectedBotUpdates: Paginator<HubtypeBotUpdate> | null
}

export const initialState: State = {
  bots: [],
  selectedBot: null,
  selectedBotUpdates: null,
}

export function deserialize(json: State): State {
  return {
    bots: json.bots
      ? jsonConverter.deserializeArray(json.bots, HubtypeBot)
      : [],
    selectedBot: json.selectedBot
      ? jsonConverter.deserializeObject(json.selectedBot, HubtypeBot)
      : null,
    selectedBotUpdates: json.selectedBotUpdates
      ? jsonConverter.deserializeObject(
          json.selectedBotUpdates,
          HubtypeBotUpdatePaginator
        )
      : null,
  }
}

export function reducer(
  state = initialState,
  action: auth.Actions | bot.Actions | organization.Actions
): State {
  const newState = reducerCore(state, action)
  if (newState.selectedBot && !(newState.selectedBot instanceof HubtypeBot)) {
    // if reloading browser with F5, selectBot is just a json
    newState.selectedBot = jsonConverter.deserializeObject(
      newState.selectedBot,
      HubtypeBot
    )
  }
  return newState
}

function reducerCore(
  state = initialState,
  action: auth.Actions | bot.Actions | organization.Actions
): State {
  switch (action.type) {
    case auth.LOGOUT:
      return initialState
    case organization.SET_ORGANIZATION:
      return {
        ...state,
        bots: action.payload.organization.bots || [],
      }
    case bot.SET_BOTS:
      return {
        ...state,
        bots: action.payload || [],
      }
    case bot.SELECT_BOT:
      return {
        ...state,
        selectedBot: action.payload,
        selectedBotUpdates: null,
      }
    case bot.CREATE_BOT:
      return {
        ...state,
        bots: addDeleteBot(state.bots, action.payload, true),
      }
    case bot.DELETE_BOT:
      return {
        ...state,
        bots: addDeleteBot(state.bots, action.payload, false),
        selectedBot: null,
        selectedBotUpdates: null,
      }
    case bot.UPDATE_BOT:
      return {
        ...state,
        bots: updateBots(state.bots, action.payload),
        selectedBot: action.payload,
      }
    case bot.SET_UPDATES:
      return {
        ...state,
        selectedBotUpdates: jsonConverter.deserializeObject(
          action.payload,
          HubtypeBotUpdatePaginator
        ),
      }
    case bot.PATCH_DEBUG:
      return {
        ...state,
        selectedBot: jsonConverter.deserializeObject(
          { ...state.selectedBot, is_debug: action.payload },
          HubtypeBot
        ),
      }

    case organization.ADD_PROVIDER_ACCOUNT:
    case organization.UPDATE_PROVIDER_ACCOUNT:
      const updatedBots = addUpdateDeleteProviderAccount(
        state.bots,
        action.payload,
        true
      )
      return {
        ...state,
        bots: updatedBots,
        selectedBot: state.selectedBot
          ? updatedBots[
              updatedBots.findIndex(bot => bot.id === state.selectedBot.id)
            ]
          : state.selectedBot,
      }
    case organization.DELETE_PROVIDER_ACCOUNT:
      const deletedBots = addUpdateDeleteProviderAccount(
        state.bots,
        action.payload,
        false
      )
      return {
        ...state,
        bots: deletedBots,
        selectedBot: state.selectedBot
          ? deletedBots[
              deletedBots.findIndex(bot => bot.id === state.selectedBot.id)
            ]
          : state.selectedBot,
      }
    default:
      return state
  }
}

function updateBots(bots: HubtypeBot[], bot: HubtypeBot) {
  const index = bots.findIndex(b => b.id === bot.id)
  if (index === -1) {
    return bots
  }
  bots[index].name = bot.name
  return bots
}

function addDeleteBot(
  bots: HubtypeBot[],
  b: HubtypeBot,
  add: boolean = true
): HubtypeBot[] {
  console.log(bots, b, b.id)
  const index = bots.map(bot => bot.id).indexOf(b.id)
  if (add && index === -1) {
    bots = [...bots, b]
  } else if (!add && index != -1) {
    bots = [...bots.slice(0, index), ...bots.slice(index + 1)]
  }
  console.log(bots, index)
  return bots
}

function addUpdateDeleteProviderAccount(
  bots: HubtypeBot[],
  providerAccount: HubtypeProviderAccount,
  add: boolean = true
) {
  if (!providerAccount.bot_id) {
    return bots
  }
  const botIndex = bots.findIndex(b => b.id === providerAccount.bot_id)
  if (botIndex === -1) {
    return bots
  }
  const bot = jsonConverter.deserializeObject({ ...bots[botIndex] }, HubtypeBot)
  bots[botIndex] = bot

  const index = bot.provider_accounts.findIndex(
    pa => pa.id === providerAccount.id
  )
  if (add && index === -1) {
    bot.provider_accounts = [...bot.provider_accounts, providerAccount]
  } else if (add && index != -1) {
    bot.provider_accounts = [
      ...bot.provider_accounts.slice(0, index),
      providerAccount,
      ...bot.provider_accounts.slice(index + 1),
    ]
  } else if (!add && index != -1) {
    bot.provider_accounts.splice(index, 1)
  }
  return [...bots]
}
