import { Utilities } from '../../shared/utilities'
import { AiAgentCopilotService } from '../ai-agent-copilot.service'
import { AppVersionService } from '../app-version.service'
import { ConverterService } from '../converter.service'
import { DesktopNotifications } from '../desktop-notifications'
import { IdleGuardService } from '../idle-guard.service'
import { LicenseService } from '../license.service'
import { PusherService } from '../pusher.service'
import { AiService } from './ai.service'
import { AuthService } from './auth.service'
import { BotService } from './bot.service'
import { CaseSearchService } from './case-search.service'
import { CaseService } from './case.service'
import { ChatService } from './chat.service'
import { HubtypeApiService } from './hubtype-api.service'
import { OrganizationService } from './organization.service'
import { ProjectService } from './project.service'
import { ProviderAccountService } from './provider-account.service'
import { QueueService } from './queue.service'
import { TemplateService } from './template.service'
import { UserService } from './user.service'

export const HubtypeApiProviders = [
  { provide: 'apiService', useClass: HubtypeApiService },
  { provide: 'authService', useClass: AuthService },
  { provide: 'botService', useClass: BotService },
  { provide: 'organizationService', useClass: OrganizationService },
  { provide: 'chatService', useClass: ChatService },
  { provide: 'userService', useClass: UserService },
  { provide: 'caseService', useClass: CaseService },
  { provide: 'caseSearchService', useClass: CaseSearchService },
  { provide: 'providerAccountService', useClass: ProviderAccountService },
  { provide: 'projectService', useClass: ProjectService },
  { provide: 'templateService', useClass: TemplateService },
  { provide: 'queueService', useClass: QueueService },
  { provide: 'pusherService', useClass: PusherService },
  { provide: 'Utilities', useClass: Utilities },
  { provide: 'appVersionService', useClass: AppVersionService },
  { provide: 'desktopNotifications', useClass: DesktopNotifications },
  { provide: 'convertService', useClass: ConverterService },
  { provide: 'idleGuardService', useClass: IdleGuardService },
  { provide: 'licenseService', useClass: LicenseService },
  { provide: 'aiService', useClass: AiService },
  { provide: 'aiAgentCopilotService', useClass: AiAgentCopilotService },
]
